@import '../../App.scss';

.right_container {
  background-color: $dark_color;
  width: 30%;
  @include media('<=760px') {
    display: none;
  }
  .container {
    padding-right: 6vw;
    .right_header {
      height: 15vh;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .profile {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 3px solid #fff;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
    }

    .slider_btn_container {
      height: 20vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;

      .fa-angle-up {
        padding: 12px 10px;
        background-color: #588795;
        border-top-left-radius: 90px;
        border-top-right-radius: 90px;
        color: #fff;
        font-size: 18px;
        cursor: pointer;
        @include media('<=SMdesktop') {
          font-size: 12px;
        }
      }
      .fa-angle-down {
        padding: 12px 10px;
        background-color: #fff;
        border-bottom-left-radius: 90px;
        border-bottom-right-radius: 90px;
        color: #36707e;
        font-size: 18px;
        cursor: pointer;
        @include media('<=SMdesktop') {
          font-size: 12px;
        }
      }
    }
  }
}
