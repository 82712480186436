//--> Font Family
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');

// Scss files
@import './breakpoints';

// --> Resets
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a,
button,
textarea,
input {
  outline: none;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

*,
html,
body {
  font-family: 'Poppins', sans-serif;
}

//--> Color scheme
$light_color: #f7fafa;
$dark_color: #36717f;
$cup_bg: #cadddc;

// App styling
.page_wrapper {
  display: flex;
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;

  .tea_container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-20%, -50%);
    background-color: $cup_bg;
    width: 600px;
    height: 400px;
    z-index: 2;
    @include media('>=1900px') {
      width: 900px;
      height: 670px;
    }
    @include media('<=1200px') {
      width: 500px;
      height: 390px;
    }
    @include media('<=970px') {
      width: 360px;
      height: 320px;
    }
    @include media('<=760px') {
      display: none;
    }

    .inner_tea_container {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .search_container {
        position: absolute;
        left: -100px;
        bottom: -5%;
        z-index: 5;
        margin-top: 2.5rem;
        @include media('>=1900px') {
          bottom: -2%;
        }
        @include media('<=SMdesktop') {
          left: -70px;
        }
        @include media('<=970px') {
          left: -50px;
        }

        input {
          outline: none;
          border: none;
          padding: 10px 5px 10px 20px;
          background: $dark_color;
          opacity: 0.6;
          color: #fff;
          width: 150px;
          @include media('<=SMdesktop') {
            width: 110px;
          }
          @include media('<=970px') {
            width: 90px;
            padding: 5px 2px 5px 10px;
          }
        }

        input::placeholder {
          color: #fff;
        }

        button {
          width: 30px;
          padding: 10px 0;
          border: none;
          outline: none;
          background: $dark_color;
          opacity: 0.6;
          color: #fff;
          cursor: pointer;
          @include media('<=970px') {
            width: 20px;
            padding: 5px 0;
            .fas {
              font-size: 10px;
            }
          }
        }
      }

      .cup_text {
        position: absolute;
        top: 17%;
        right: -10px;
        transform: rotate(90deg);
        font-size: 1rem;
        color: $dark_color;
        font-weight: 500;
        @include media('>=1900px') {
          font-size: 1.5rem;
        }
        @include media('<=SMdesktop') {
          font-size: 0.7rem;
        }
      }

      .cup_img {
        user-select: none;
        pointer-events: none;
        width: 270px;
        height: 270px;
        border-radius: 50%;
        box-shadow: 40px 3px 13px -20px rgba(54, 112, 127, 1);
        -webkit-box-shadow: 40px 3px 13px -20px rgba(54, 112, 127, 1);
        -moz-box-shadow: 40px 3px 13px -20px rgba(54, 112, 127, 1);
        @include media('>=1900px') {
          width: 370px;
          height: 370px;
        }
        @include media('<=1200px') {
          width: 200px;
          height: 200px;
        }
        @include media('<=970px') {
          width: 150px;
          height: 150px;
          box-shadow: 30px 3px 13px -20px rgba(54, 112, 127, 1);
          -webkit-box-shadow: 30px 3px 13px -20px rgba(54, 112, 127, 1);
          -moz-box-shadow: 30px 3px 13px -20px rgba(54, 112, 127, 1);
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
